import React from "react";

function OverviewIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 255.12 255.12'>
            <g id='Ebene_1' data-name='Ebene 1'>
                <path fill='var(--primary-font-color)' d='M66.06,53.56v25a6.25,6.25,0,0,1-6.25,6.25h-25a6.25,6.25,0,0,1-6.25-6.25v-25a6.25,6.25,0,0,1,6.25-6.25h25A6.25,6.25,0,0,1,66.06,53.56Zm0,62.5v25a6.25,6.25,0,0,1-6.25,6.25h-25a6.25,6.25,0,0,1-6.25-6.25v-25a6.25,6.25,0,0,1,6.25-6.25h25A6.25,6.25,0,0,1,66.06,116.06Zm0,62.5v25a6.25,6.25,0,0,1-6.25,6.25h-25a6.25,6.25,0,0,1-6.25-6.25v-25a6.25,6.25,0,0,1,6.25-6.25h25A6.25,6.25,0,0,1,66.06,178.56ZM228.56,59.81v12.5a6.25,6.25,0,0,1-6.25,6.25h-125a6.25,6.25,0,0,1-6.25-6.25V59.81a6.25,6.25,0,0,1,6.25-6.25h125A6.25,6.25,0,0,1,228.56,59.81Zm0,62.5v12.5a6.25,6.25,0,0,1-6.25,6.25h-125a6.25,6.25,0,0,1-6.25-6.25v-12.5a6.25,6.25,0,0,1,6.25-6.25h125A6.25,6.25,0,0,1,228.56,122.31Zm0,62.5v12.5a6.25,6.25,0,0,1-6.25,6.25h-125a6.25,6.25,0,0,1-6.25-6.25v-12.5a6.25,6.25,0,0,1,6.25-6.25h125A6.25,6.25,0,0,1,228.56,184.81Z'>
                </path>
            </g>
        </svg>
    )
}

export default OverviewIcon;