import React from 'react'

function YucatanIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 255.12 255.12'>
            <g id='Ebene_1' data-name='Ebene 1'>
                <ellipse id='Ellipse_1489' data-name='Ellipse 1489' fill='var(--primary-font-color)' cx='127.56' cy='215.45' rx='88.63' ry='11.82'></ellipse>
                <path id='tree' fill='var(--primary-font-color)' class='cls-2' d='M193.1,214.29c-4.54,0-4.51-1.26-4.51-1.26V189.21a25.14,25.14,0,1,1-6.86-49.32h1.14a20.59,20.59,0,0,1,36.07,19.5,20.22,20.22,0,0,1,6.22,14.77v.17a20.54,20.54,0,0,1-27.43,19.21V213S197.63,214.29,193.1,214.29Z'>
                </path>
                <path id='tree_1_2' data-name='tree 1 2' fill='var(--primary-font-color)' d='M63.64,213V189.11A25.22,25.22,0,0,1,30,165.54v-.23a24.78,24.78,0,0,1,7.63-18.13,25.29,25.29,0,0,1,44.29-24h1.4a30.87,30.87,0,1,1-8.42,60.56V213s-1,2.15-5.73,2.15S63.64,213,63.64,213Z'>
                </path><g id='tree_1_3' data-name='tree 1 3'>
                    <path fill='var(--primary-font-color)' d='M212.22,105.56a55.36,55.36,0,0,1-55.29,55.29,56,56,0,0,1-15.12-2.13l-1.27-.36V220c-.23.27-1.73,1.61-9.21,1.61s-9-1.34-9.27-1.63V168l-1.34.48a43.82,43.82,0,0,1-14.91,2.6h-.4a45,45,0,0,1-44.9-44.65V126A44.27,44.27,0,0,1,74.14,93.68l.5-.48-.26-.63a45.19,45.19,0,0,1,79.15-42.74l.3.45h3.1a55.28,55.28,0,0,1,55.29,55.28Z'>
                    </path>
                    <path fill='var(--primary-font-color)' d='M213.22,105.56a56.29,56.29,0,0,1-56.28,56.29h0a57.13,57.13,0,0,1-15.38-2.17v44.1l-1,0v-45.4l1.27.36a56,56,0,0,0,15.12,2.13,55.29,55.29,0,1,0,0-110.57h-3.1l-.3-.45A45.19,45.19,0,0,0,74.38,92.57l.26.63-.5.48A44.27,44.27,0,0,0,60.51,126v.41a45,45,0,0,0,44.9,44.65h.4a43.82,43.82,0,0,0,14.91-2.6l1.34-.48v35.65h-1V169.42a44.76,44.76,0,0,1-15.38,2.66,45.89,45.89,0,0,1-46.16-45.64V126A45.09,45.09,0,0,1,73.45,93a46.19,46.19,0,0,1,80.91-43.68h2.56A56.29,56.29,0,0,1,213.22,105.56Z'>
                    </path>
                    <path fill='var(--background-color)' d='M156.93,48.28h-2A47.19,47.19,0,0,0,72.27,92.71,46.27,46.27,0,0,0,58.51,126v.41a47,47,0,0,0,46.89,46.64h.27a45.81,45.81,0,0,0,14.39-2.26v32.85h2V168l-1.34.48a43.82,43.82,0,0,1-14.91,2.6h-.4a45,45,0,0,1-44.9-44.65V126A44.27,44.27,0,0,1,74.14,93.68l.5-.48-.26-.63a45.19,45.19,0,0,1,79.15-42.74l.3.45h3.1a55.29,55.29,0,1,1,0,110.57,56,56,0,0,1-15.12-2.13l-1.27-.36v45.4l1,0,1,0V161a58.09,58.09,0,0,0,14.4,1.86,57.29,57.29,0,1,0,0-114.57Z'>
                    </path>
                </g>
            </g>
        </svg>
    )
}
export default YucatanIcon