import React from 'react';

function StopTalkingStartPlantingIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 255.12 255.12'>
            <g id='Ebene_1' data-name='Ebene 1'>
                <path fill='var(--primary-font-color)' d='M238.15,88.15c0,66.8-42.82,121.36-103.66,125.78C103.14,217.26,79.27,201,66.72,184,47,200.56,39.44,217.73,38.86,219.12a12.42,12.42,0,0,1-23-9.42c9.14-22.43,50.45-82.15,147.87-82.15a6.2,6.2,0,0,0,0-12.4c-50.61,0-86.3,15-111,32.75a77,77,0,0,1-.58-8,74.41,74.41,0,0,1,74.4-74.39h31c24.57,0,46.07-13,58.08-33.91a6,6,0,0,1,11,.46C234,48.54,238.15,68.69,238.15,88.15Z'>
                </path></g></svg>
    )
}

export default StopTalkingStartPlantingIcon;