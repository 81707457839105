import React from 'react';

function TrillionTreesIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 255.12 255.12'>
            <g id='Ebene_1' data-name='Ebene 1'>
                <path fill='var(--primary-font-color)' d='M226.77,127.56a99.21,99.21,0,1,1-99.21-99.21A99.21,99.21,0,0,1,226.77,127.56ZM166,158.21h0a14,14,0,0,0-3.57-8.63l-5.48-5.47a12.78,12.78,0,0,0-9-3.75H121.16c-3.76-1.89-8.59-12.8-12.8-12.8a27.11,27.11,0,0,1-12.12-2.87l-4.44-2.22a4.77,4.77,0,0,1-2.64-4.27h0a5.09,5.09,0,0,1,3.27-4.54l12.47-4.16a7.67,7.67,0,0,1,2-.32,7.46,7.46,0,0,1,4.2,1.56l3.71,3.23a3.21,3.21,0,0,0,2.1.78h2.25a3.2,3.2,0,0,0,2.86-4.63l-6.23-12.48a3.7,3.7,0,0,1-.34-1.43,3.65,3.65,0,0,1,1-2.29l4-3.86a3.17,3.17,0,0,1,2.23-.91h3.6a3.67,3.67,0,0,0,2.26-.94l3.2-3.2a3.19,3.19,0,0,0,0-4.52l-1.88-1.88a3.21,3.21,0,0,1,0-4.52L134,70l1.88-1.87a6.4,6.4,0,0,0,0-9L124.51,47.7a82.33,82.33,0,0,0-9.75,1v4.52a6.41,6.41,0,0,1-9.27,5.73l-9.6-4.81A80.45,80.45,0,0,0,54.6,94.87c4,5.89,10.09,14.95,13.84,20.46a55.4,55.4,0,0,0,7.22,8.3l.32.29a57.6,57.6,0,0,0,12.67,8.7c5.6,2.82,13.77,7.26,19.52,10.45a12.75,12.75,0,0,1,6.59,11.18v12.81a12.79,12.79,0,0,0,3.75,9.05c6,6,9.73,15.46,9,20.51v10.95a79.91,79.91,0,0,0,17.06-1.88l7-18.74c.81-2.2,1.3-4.48,1.91-6.75a9.53,9.53,0,0,1,2.46-4.28l4.53-4.52A21.35,21.35,0,0,0,166,158.21Zm40.87-20.43a80.2,80.2,0,0,0,.73-10.22A79.39,79.39,0,0,0,199,91.82l-5.19,2.6a9.54,9.54,0,0,0-3.7,3.26l-7.84,11.75a9.61,9.61,0,0,0,0,10.65l7.2,10.79a9.57,9.57,0,0,0,5.66,4Z'>
                </path>
            </g>
        </svg>
    )
}

export default TrillionTreesIcon;