import React from 'react';

function ChangeChocolateIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 255.12 255.12'>
            <g id='Ebene_1' data-name='Ebene 1'>
                <path id='Pfad_9275' data-name='Pfad 9275' fill='var(--primary-font-color)' d='M32.48,154.07,76.35,110.2l-2.09-.65a3.9,3.9,0,0,1-2.57-4.88,3.94,3.94,0,0,1,1-1.61L92.94,82.79a3.9,3.9,0,0,1,5.52,0h0l2.66,2.65,55.94-55.95a3.9,3.9,0,0,1,5.52,0h0l33,33h0l33,33a3.89,3.89,0,0,1,0,5.51h0l-29.34,29.34h0L172.72,157l2.65,2.66a3.89,3.89,0,0,1,0,5.51h0L155.1,185.44a3.91,3.91,0,0,1-5.53,0,3.82,3.82,0,0,1-1-1.59l-.65-2.09-43.87,43.87a3.9,3.9,0,0,1-5.51,0h0l-66.09-66a3.9,3.9,0,0,1,0-5.51Zm101.66-35.61L158,94.62,130.47,67.15,106.63,91Zm25.68-80.69L136,61.61,163.5,89.13l23.84-23.84Zm33,33L169,94.65l27.52,27.52,23.84-23.84ZM191,127.68,163.5,100.16,139.67,124l27.51,27.52ZM167.1,162.45l-2.66-2.66h0l-33-33h0L95.69,91.07,82.75,104l23.18,7.18a3.9,3.9,0,0,1,2.58,2.57l8.5,27.4c28.64,8.86,29.6,9.82,30,11.06l7.18,23.18Z'>
                </path>
            </g>
        </svg>
    )
}

export default ChangeChocolateIcon